import { getApiUrl } from "@app/shared";
import { FC } from "react";
import { MaxHeightContainer } from "../../shared/charts/MaxHeightContainer";

export const Jobs: FC = (props) => {

    return (
        <MaxHeightContainer minHeight={300} margin={{ top: 0, bottom: 0, left: 0, right: -64 }}>{({ width, height }) =>
            <iframe style={{border: 'none',overflow:'hidden'}} src={getApiUrl("/jobs")} height={height} width={width} title="Hangfire admin ui" />
        }</MaxHeightContainer>
    );
}