import { ApiResult, Facilities, Facility } from "@app/shared";
import { toastWarning } from "../helpers";
import { useFetch } from "./useFetch";
import { useState, useEffect } from 'react';
import { useSessionStorage } from "react-use";

export function useFacilityTZ(facility: string | Facility, warnOnUTC: boolean = false): [facilityTimezone: string | undefined, error: string | undefined, { isLoading: boolean | undefined }] {

    const [fetchedFacility, error, { isLoading }] = useFetch(() => { return typeof (facility) === 'object' ? ApiResult.resolve(facility) : Facilities.getById(facility) }, [facility]);
    const [hasBeenWarned, setHasBeenWarned] = useSessionStorage('reports_utc_warning_shown', false);
    const [facilityTZ, setFacilityTZ] = useState(typeof (facility) === 'object' ? (facility as unknown as Facility).localTimezoneId : undefined);
    const [tzIsLoading, setTZIsLoading] = useState(typeof (facility) !== 'object');

    useEffect(() => {
        if (isLoading || !!!fetchedFacility) return;
        if (fetchedFacility.localTimezoneId === 'UTC' && !hasBeenWarned && warnOnUTC) {
            setHasBeenWarned(true);
            toastWarning('Default Timezone', `Reports are shown in location Timezone which is currently set to ${fetchedFacility.localTimezoneId}`);
        }
        setFacilityTZ(fetchedFacility.localTimezoneId);
        setTZIsLoading(false);
    }, [isLoading, fetchedFacility, setHasBeenWarned, hasBeenWarned, warnOnUTC]);

    return [facilityTZ, error ?? '', { isLoading: tzIsLoading }];
}