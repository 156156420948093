/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface ILuxcomEndpointConfigDetector {
	detector: number;

	expression: string;

	$type?: string | undefined;
}
export class LuxcomEndpointConfigDetector {
	constructor(data?: ILuxcomEndpointConfigDetector) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	detector: number = 0;

	@IsNotEmpty()
	expression: string = "";

	static $type: string = "Shared.Models.Api.V1.ClientEndpoints.LuxcomEndpointConfigDetector, ";
	$type?: string | undefined = LuxcomEndpointConfigDetector.$type;
}

