import { AggregatedTargetAttribute, AggregationPeriod, AverageDailyVolume, Reports, getTargetTypeDisplayName, useAuth } from "@app/shared";
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AverageDailyVolumeByYearLoader } from "./AverageDailyVolumeByYear";
import { ReportContext, buildLabeler, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { format } from "date-fns";
import { useContext, useMemo } from "react";


const ChartDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data,] = useFetch(() => Reports.getAverageDailyVolumes(reportConfig.grouping, AggregationPeriod.Year, searchParams), [searchParams]);
    //TODO: Should avoid loading data here by using the monthly loader instead of the yearly so we don't lose month as data passes through components

    const tickLabeler = buildLabeler((str) => {
        const year = parseInt(str);
        const datum = data?.find(x => x.year === year);
        if (datum) {
            return format(new Date(year, (datum as any).month - 1, 1), 'MMMM yyyy')
        }
        return str;
    });
    const toolTip = useMemo(() => {
        if (!data?.length)
            return undefined;
        if (reportConfig.grouping !== AggregatedTargetAttribute.None) {
            return stackedTooltipBuilder<AverageDailyVolume>(numberFormatter, (d, tt) => getTargetTypeDisplayName(tt as any)!, undefined, 'Design Day Volume');
        }
        return tooltipBuilder<AverageDailyVolume>(x => {
            const datum = data?.find(y => y.year === x.year);
            return format(new Date(datum!.year, (datum as any)!.month - 1, 1), 'MMMM yyyy')
        }, v => numberFormatter.format(v.avgVolume), 'Design Day Volume')
    }, [reportConfig.grouping, data, numberFormatter])

    return (<AverageDailyVolumeByYearLoader {...props}
        dataToChart={x => Reports.getAverageDailyVolumes(reportConfig.grouping, AggregationPeriod.Year, x)}
        xTickLabeler={tickLabeler}
        toolTip={toolTip}
    />);
}


export const DesignDayVolumesReport = {
    name: "Annual Design Day Volume Report",
    description: "This report shows the month with the maximum average daily volume for every year",
    component: ChartDataLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    key: 'add',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;