/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, post } from "./api_client";
import { AggregatedTargetAttribute, AggregationPeriod } from "../models";
import { TotalVolumeByPeriod, ITotalVolumeByPeriod, AverageDailyVolume, IAverageDailyVolume, LocationUsageGrouping, ILocationUsageGrouping, TrafficReportConfiguration, ITrafficReportConfiguration } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function busiestDay(opts: TrafficReportConfiguration): ApiResult<TotalVolumeByPeriod[]> {
	return post<ITotalVolumeByPeriod[]>(`/api/report/busiest-day`, opts).then(result => result === undefined ? undefined : result.map(o => new TotalVolumeByPeriod(o)));
}

export async function getTotalVolume(attribute: AggregatedTargetAttribute, period: AggregationPeriod, opts: TrafficReportConfiguration): ApiResult<TotalVolumeByPeriod[]> {
	return post<ITotalVolumeByPeriod[]>(`/api/report/total-volume/by-${ attribute }/by-${ period }`, opts).then(result => result === undefined ? undefined : result.map(o => new TotalVolumeByPeriod(o)));
}

export async function getAverageDailyVolumes(attribute: AggregatedTargetAttribute, period: AggregationPeriod, opts: TrafficReportConfiguration): ApiResult<AverageDailyVolume[]> {
	return post<IAverageDailyVolume[]>(`/api/report/average-daily-volumes/by-${ attribute }/by-${ period }`, opts).then(result => result === undefined ? undefined : result.map(o => new AverageDailyVolume(o)));
}

export async function facilityScreenLineTotals(level: AggregationPeriod, opts: TrafficReportConfiguration): ApiResult<LocationUsageGrouping[]> {
	return post<ILocationUsageGrouping[]>(`/api/report/facility-usage/by-${ level }`, opts).then(result => result === undefined ? undefined : result.map(o => new LocationUsageGrouping(o)));
}
