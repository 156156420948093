import { NtcipDmsClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { Input, InputRow } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditNtcipDmsClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditNtcipDmsClientEndpoint: React.FC<EditNtcipDmsClientEndpointProps> = (props) => {
    const [, helper] = useModel(NtcipDmsClientEndpoint, props.endpoint as NtcipDmsClientEndpoint);
    const onChange = (newEndpoint: Partial<NtcipDmsClientEndpoint>) => {
        props.onChange(newEndpoint as NtcipDmsClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
        </>
    );
}