import { ILuxcomEndpointConfig, ILuxcomEndpointConfigDetector, LuxcomEndpointConfig, LuxcomEndpointConfigDetector, OptionValue } from "@app/shared";
import { Input, InputRow, Select } from "../inputs";
import { EditEndpointConfigProps } from "./EditEndpointConfig";

type EditLuxcomEndpointConfigProps = {
} & EditEndpointConfigProps;

const getNumberArray = (start: number, end: number) => {
    return Array.from({ length: (end - start + 1) }, (_, i) => i + start)
}
const getNumberOptions = (start: number, end: number) => {
    return getNumberArray(start, end).map(i => new OptionValue({ label: i + "", value: i + "" }));
}
const biuNumOptions = getNumberOptions(1, 4);
const numDetectorOptions = getNumberOptions(1, 16);

export const EditLuxcomEndpointConfig: React.FC<EditLuxcomEndpointConfigProps> = (props) => {

    const config = new LuxcomEndpointConfig(JSON.parse((props.value?.length ?? 0) < 1 ? "{}" : props.value!) as ILuxcomEndpointConfig)

    const updateParent = () => {
        props.onChange(JSON.stringify(config))
    };

    const onDetectorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCount = parseInt(e.target.value);
        if ((config.detectors?.length ?? 0) < 1) {
            config.detectors = [new LuxcomEndpointConfigDetector({ detector: 1 } as ILuxcomEndpointConfigDetector)];
        }

        if (config.detectors!.length > newCount) {
            config.detectors = config.detectors?.splice(0, newCount);
        } else if (config.detectors!.length < newCount) {
            config.detectors = [...config.detectors!, ...getNumberArray(config.detectors!.length + 1, newCount).map(i => new LuxcomEndpointConfigDetector({ detector: i } as ILuxcomEndpointConfigDetector))];
        }
        updateParent();
    }

    return (<>
        <InputRow><Select label="Biu Num" value={config.biuNum} options={biuNumOptions} onChange={(e) => { config.biuNum = parseInt(e.target.value); updateParent(); }} /></InputRow>
        <InputRow><Select label="Number of Detectors" onChange={onDetectorChange} value={config.detectors?.length ?? 0} options={numDetectorOptions} /></InputRow>
        {
            config.detectors?.map((d, i) => <InputRow key={i + "-" + d}>
                <div className="grid grid-cols-3 gap-5">
                    <div><Input label="Detector#" value={d.detector} onChange={(e) => { d.detector = parseInt(e.target.value); updateParent(); }}></Input></div>
                    <div className="col-span-2"><Input label="Evaluation" value={d.expression ?? ""} onChange={(e) => { d.expression = e.target.value; updateParent(); }}></Input></div>
                </div>
            </InputRow>)
        }
    </>
    );
}