import cn from "classnames";
import { FunctionComponent, ReactElement, useRef, useState, useMemo } from "react";
import { IconType } from "react-icons";
import { NavLink, NavLinkProps, To } from "react-router-dom";
import { HoverTooltip } from "../shared/HoverTooltip";
import { CustomClaimValue, CustomClaimValueDisplayNames, useAuth } from "@app/shared";
import { toastWarning } from "../../helpers";
import { useHasClaim } from "../../hooks";

type SidebarIconProps = Omit<NavLinkProps, "className" | "to"> & {
    Icon?: IconType;
    to?: To;
    className?: string;
    claim?: CustomClaimValue;
    hover: string | ReactElement;
};

export const SidebarIcon: FunctionComponent<SidebarIconProps> = (props) => {
    const { Icon, claim, to, children, hover, ...rest } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const popperButtonRef = useRef(null);
    const auth = useAuth();

    const wrappedChildren = <>
        <div className="w-2"></div>
        {Icon &&
            <Icon className='w-9 h-9 mx-auto' />
        }
        {children}
        <div className="w-2 flex flex-col justify-center">
            <div className="nav-triangle" />
        </div>
    </>;
    const hasClaim = useHasClaim(claim!, auth.currentOrganizationId, undefined);
    const hasPermission = useMemo(() => {
        if (!claim) { return true; }
        return !!hasClaim;
    }, [claim, hasClaim]);

    const onClick = () => {
        if (claim && !hasPermission) {
            toastWarning('Permission Error', `${CustomClaimValueDisplayNames[claim]} claim is required.`);
        }
    };

    const HoverNode = (to && hasPermission) ?
        < NavLink to={to} {...rest} className={({ isActive }) => cn("w-full flex flex-grow ", { "text-link-left-nav-active nav-active": isActive })}>
            {wrappedChildren}
        </NavLink >
        :
        (hasPermission ? wrappedChildren :
            <div className="w-full flex flex-grow text-[#FFFFFF] text-opacity-25 cursor-not-allowed" onClick={onClick}>
                {wrappedChildren}
            </div>);

    return (
        <HoverTooltip popperButtonRef={popperButtonRef} show={showTooltip} hoverable={
            <li ref={popperButtonRef} className={cn("w-full text-link-left-nav flex place-content-end ", { "hover:text-link-left-nav-hover": hasPermission })}
                onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} >
                {HoverNode}
            </li >
        } placement="right" offset={[-27, 0]} className="h-8 bg-background-left-nav rounded-2xl text-sm font-semibold border border-bright-text text-bright-text px-3 py-0 flex items-center" >
            {hover}
        </HoverTooltip>
    );
}