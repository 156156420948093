import { FC, useState } from "react";

import { useParams } from "react-router-dom";
import ShakaPlayer from "shaka-player-react";
import { Devices, Facilities, Target, TargetStatus } from "@app/shared";

import { useFetch } from "../../../hooks";
import { useLiveViewHub } from "../../../hooks/useLiveViewHub";
import { LayoutDesigner } from "../../shared/LayoutDesigner/LayoutDesigner";
import { SvgTargetHistory } from "../../shared/LayoutDesigner/SvgTargetHistory";

export const DeviceDemo: FC = () => {
  const { id } = useParams();

  const [recentTargets, setRecentTargets] = useState<Target[]>([]);

  const [device] = useFetch(() => Devices.getById(id));
  const [facility] = useFetch(() => Facilities.getById(device?.facilityId), [device]);
  const [streamUrl] = useFetch(() => Devices.getDeviceStream(device?.id), [device]);

  useLiveViewHub(facility?.id, (targets: Target[]) => {
    const interestingTargets = recentTargets.filter(t => t.status === TargetStatus.Moving || t.status === TargetStatus.New);
    const newTargets = [...targets, ...interestingTargets.filter(t => !targets.find(nt => nt.id === t.id))];

    setRecentTargets(newTargets);
  });

  return (
    <div className="min-h-full h-full flex gap-6 p-6">
      <div className="flex-1 my-auto">
        {facility?.backgroundImageSource &&
          <LayoutDesigner facility={facility}>
            {recentTargets.map(t => (
              <SvgTargetHistory hideHistory key={t.id} color={device?.color} target={t} facility={facility} showTargets={false} />
            ))}
          </LayoutDesigner>
        }
      </div>
      <div className="flex-1 my-auto">
        {streamUrl &&
          <div className="bg-background-left-nav">
            <ShakaPlayer autoPlay src={streamUrl} />
          </div>
        }
      </div>
    </div>
  );
}