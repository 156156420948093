export enum AggregatedTargetAttribute {
	None = 0,
	FHWAClass = 1,
	Lane = 2,
	TargetType = 3,
}

export const AggregatedTargetAttributeDisplayNames: { [key in keyof typeof AggregatedTargetAttribute]?: string} = {
	"None": "None",
	0 : "None",
	"FHWAClass": "FHWA Class",
	1 : "FHWA Class",
	"Lane": "Traffic Lane",
	2 : "Traffic Lane",
	"TargetType": "Target Type",
	3 : "Target Type",
}

export const getAggregatedTargetAttributeDisplayName = (key: AggregatedTargetAttribute | (keyof typeof AggregatedTargetAttribute & string)) => {
	return AggregatedTargetAttributeDisplayNames[key];
}

