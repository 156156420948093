import { Devices } from "@app/shared";
import { useState } from "react";
import { Dialog, ErrorAlert } from ".";
import { Input, InputRow } from "./inputs";


type DeviceProvisionDialogProps = {
    show: boolean;
    onCancel: () => void;
    onFinished: () => void;
    facilityId: string;
};

export const DeviceProvisionDialog: React.FC<DeviceProvisionDialogProps> = (props) => {    
    const [deviceKey, setDeviceKey] = useState<string>();
    const [error, setError] = useState<string>();

    const handleOnOkClick = async () => {
        var idToAssociate;
        //Lookup the device
        var device = await Devices.getUnassociatedDevice(deviceKey);
        if (!device) {
            setError(`Unable to find device with key ${deviceKey}`);
        } else {
            idToAssociate = device.id;

        }
        await Devices.associateDevice(idToAssociate, props.facilityId);
        await props.onFinished();
    };

    return (
        <Dialog show={props.show} title={"Add Device"} onCancelClick={props.onCancel} onOkClick={handleOnOkClick}>
            <>
                <p>
                    Please enter the device code from the back of the device
                </p>
                <ErrorAlert errors={error} />
                <InputRow>
                    <Input label="Device Key" value={deviceKey} onChange={(e) => setDeviceKey(e.target.value)} />
                </InputRow>
            </>

        </Dialog>
    );
}