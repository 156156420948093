import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeDataLoader } from "./TotalVolumeDataLoader";
import { TotalVolumeByMonth } from "./TotalVolumeByMonth";
import { useContext } from "react";
import { ReportContext } from "./reportHelpers";
import { zonedTimeToUtc } from "date-fns-tz";
import { AggregationPeriod } from "@app/shared";

const YearlyDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, updateReportConfig } = useContext(ReportContext);

    function handleClick(date: Date): void {//works 6/2024

        const startLocal = new Date(date.getFullYear(), 0, 1);
        const end = new Date(date.getFullYear() + 1, 0, 1);

        const cfg = {
            ...reportConfig,
            selectedReportId: TotalVolumeByMonth.key,
            startTime: zonedTimeToUtc(startLocal.toISOString(), reportConfig.chartTimezone),
            endTime: zonedTimeToUtc(end.toISOString(), reportConfig.chartTimezone)
        };
        updateReportConfig(cfg);
    }

    return (<TotalVolumeDataLoader
        {...props}
        aggregationPeriod={AggregationPeriod.Year}
        onClick={handleClick}
    />);
}

export const TotalVolumeByYear: IConfiguredTrafficReport = {
    name: "by Year",
    key: "tvy",
    description: "This report shows total traffic for the specified years",
    defaultChartType: 'bar',
    component: YearlyDataLoader,
    defaultRange: DateOptions.AllData,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};