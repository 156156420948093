export enum Lane {
	Unknown = 0,
	Lane1 = 1,
	Lane2 = 2,
	Lane3 = 4,
	Lane4 = 8,
	Lane5 = 16,
	Lane6 = 32,
	Lane7 = 64,
	Lane8 = 128,
	Lane9 = 256,
	Lane10 = 512,
}

export const LaneDisplayNames: { [key in keyof typeof Lane]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"Lane1": "Lane 1",
	1 : "Lane 1",
	"Lane2": "Lane 2",
	2 : "Lane 2",
	"Lane3": "Lane 3",
	4 : "Lane 3",
	"Lane4": "Lane 4",
	8 : "Lane 4",
	"Lane5": "Lane 5",
	16 : "Lane 5",
	"Lane6": "Lane 6",
	32 : "Lane 6",
	"Lane7": "Lane 7",
	64 : "Lane 7",
	"Lane8": "Lane 8",
	128 : "Lane 8",
	"Lane9": "Lane 9",
	256 : "Lane 9",
	"Lane10": "Lane 10",
	512 : "Lane 10",
}

export const getLaneDisplayName = (key: Lane | number |  (keyof typeof Lane & string)) => {
	if (isNaN(+key)) return LaneDisplayNames[key];
	return Object.keys(LaneDisplayNames).filter(v => parseInt(v) >= 0 && (parseInt(v) & Number(key)) > 0).map(v => LaneDisplayNames[v as (keyof typeof Lane)]).join(', ');
}

