import { WebRelayClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { Input, InputRow } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditWebRelayClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditWebRelayClientEndpoint: React.FC<EditWebRelayClientEndpointProps> = (props) => {
    const [, helper] = useModel(WebRelayClientEndpoint, props.endpoint as WebRelayClientEndpoint);
    const onChange = (newEndpoint: Partial<WebRelayClientEndpoint>) => {
        props.onChange(newEndpoint as WebRelayClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
        </>
    );
}