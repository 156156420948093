export interface IDetectorStatus_V1 {
	biuNum: number;

	detectorNum: number;

	isActive: boolean;

	$type?: string | undefined;
}
export class DetectorStatus_V1 {
	constructor(data?: IDetectorStatus_V1) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	biuNum: number = 0;

	detectorNum: number = 0;

	isActive: boolean = false;

	static $type: string = "Shared.ClientEndpoints.Json.DetectorStatus_V1, ";
	$type?: string | undefined = DetectorStatus_V1.$type;
}

