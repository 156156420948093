import { ClientEndpoint, LuxcomClientEndpoint } from "@app/shared";
import { EditLuxcomEndpointConfig } from "./EditLuxcomEndpointConfig";

export type EditEndpointConfigProps = {
    endpoint?: ClientEndpoint;
    value?: string;
    onChange: (json: string | undefined) => any;
};

export const EditEndpointConfig: React.FC<EditEndpointConfigProps> = (props) => {

    if (!props || !props.endpoint || !props.endpoint.$type)
        return <></>;

    switch (props.endpoint!.$type) {
        case LuxcomClientEndpoint.$type:
            return <EditLuxcomEndpointConfig {...props} />
    }
}