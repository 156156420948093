import { Facility, Spot, Vector2 } from "@app/shared";
import { useEffect, useState } from "react";
import useScript from "react-script-hook";
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from "use-places-autocomplete";
import { ModelHelpers } from "../../hooks/useModel";
import { Input, InputRow, NumericInput, SelectTimezone } from "./inputs";
import { GooglePlacesScript, PlaceSuggestions, getAddressDescription, parseDetailsIntoAddress } from "./maps/PlaceSuggestions";

type EditFacilityDetailsInputsProps = {
    model: Partial<Facility>;
    helpers: ModelHelpers<Facility>
};

export const EditFacilityDetailsInputs: React.FC<EditFacilityDetailsInputsProps> = ({ model, helpers }) => {
    const [selectedFacility, setSelectedFacility] = useState<string>(renderAddressKey());

    const places = usePlacesAutocomplete({
        initOnMount: false,
        defaultValue: model.address1 ?? "",
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });

    useScript({
        src: GooglePlacesScript,
        onload: () => places.init()
    });

    useEffect(() => {
        let query = [
            model.address1,
            model.city,
            // Don't add country or state here to prevent premature searching
        ]
            .filter(e => e)
            .join(", ");

        places.init();
        if (query === "") {
            places.clearSuggestions();
        } else {
            if (model.country) {
                query += ", " + model.country;
            }

            if (model.state) {
                query += ", " + model.state;
            }

            places.setValue(query);
        }
    }, [places.init, places.setValue, places.clearSuggestions, model, places]);


    async function handlePlaceSelect(placeId: string) {
        const getDetailsParams = {
            placeId: placeId,
            fields: ['address_component']
        }
        var details = await getDetails(getDetailsParams);

        var address = parseDetailsIntoAddress(details as google.maps.places.PlaceResult);
        helpers.updateModel("address1", address.street);
        helpers.updateModel("city", address.city);
        helpers.updateModel("state", address.state);
        helpers.updateModel("city", address.city);
        helpers.updateModel("zip", address.zipCode);
        helpers.updateModel("country", address.country);

        setSelectedFacility(
            address.street + "|" +
            address.state + "|" +
            address.city + "|" +
            address.zipCode + "|" +
            address.country
        );

        places.clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: getAddressDescription(model) })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log("Found coordinates: ", { lat, lng });
                helpers.updateModel("originReferencePointLatLng",
                    { points: [new Vector2({ x: lng, y: lat })] } as Spot)
            })
            .catch((error) => {
                console.log("Error handling place select", error);
            });
    };

    function renderAddressKey(): string {
        return (
            model.address1 + "|" +
            model.state + "|" +
            model.city + "|" +
            model.zip + "|" +
            model.country
        );
    }

    const handleLonChange = (val: number | undefined) => {
        helpers.updateModel("originReferencePointLatLng", { points: [new Vector2({ x: val ?? 0, y: model.originReferencePointLatLng?.points?.[0]?.y ?? 0 })] } as Spot)
    };

    const handleLatChange = (val: number | undefined) => {
        helpers.updateModel("originReferencePointLatLng", { points: [new Vector2({ x: model.originReferencePointLatLng?.points?.[0]?.x ?? 0, y: val ?? 0 })] } as Spot)
    };

    return (
        <>

            <InputRow>
                <Input label="Location Name" {...helpers.bindingsFor("name")} />
            </InputRow>
            <InputRow>
                <Input label="Client Location Code" {...helpers.bindingsFor("locationCode")} />
            </InputRow>
            <InputRow>
                <SelectTimezone label="Location TimeZone" {...helpers.bindingsFor("localTimezoneId")} />
            </InputRow>
            <InputRow>
                <Input label="Address" {...helpers.bindingsFor("address1")} />
            </InputRow>
            {places.suggestions.status === "OK" && renderAddressKey() !== selectedFacility &&
                <InputRow>
                    <PlaceSuggestions
                        suggestions={places.suggestions.data}
                        onClick={placeId => handlePlaceSelect(placeId)}
                    />
                </InputRow>
            }
            <InputRow>
                <Input {...helpers.bindingsFor("address2")} />
            </InputRow>
            <InputRow addlClasses="grid grid-cols-3 gap-2">
                <Input label="City" {...helpers.bindingsFor("city")} />
                <Input label="State" {...helpers.bindingsFor("state")} />
                <Input label="Zipcode" {...helpers.bindingsFor("zip")} />
            </InputRow>
            <InputRow addlClasses="grid grid-cols-2 gap-2">
                <NumericInput label="Latitude" value={(model.originReferencePointLatLng?.points?.[0]?.y ?? "") + ""} onChange={handleLatChange} />
                <NumericInput label="Longitude" value={(model.originReferencePointLatLng?.points?.[0]?.x ?? "") + ""} onChange={handleLonChange} />

            </InputRow>
        </>
    );
}