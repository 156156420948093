import { INewUserInvite, NewUserInvite, OmniUser, Organizations, useAuth, UserInvites, Users } from "@app/shared";
import { toastError, toastSuccess } from "../../helpers";
import { useConfirmDialog, useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { DashboardLayout } from "../layouts";
import { IconLink, Icons, Input, InputLabel, InputRow, LabeledText, SortableTable, UserRoleInput } from "../shared";
import { DropDownMenu } from "../shared/DropDownMenu";

class UserWithRoles {
    id: string = "";
    name: string = "";
    email: string = "";
    roles: string[] = [];
}
export const ManageUsers: React.FC = (props) => {
    const { user, currentOrganizationId } = useAuth();
    const [org] = useFetch(() => Organizations.getById(currentOrganizationId), [currentOrganizationId]);
    const [users, , userHelpers] = useFetch(() => Users.getUsers(currentOrganizationId), [currentOrganizationId]);
    const [userInvites, , userInviteHelpers] = useFetch(() => UserInvites.getUserInvites(currentOrganizationId), [currentOrganizationId]);
    const userRolesDialog = useEditModelDialog(UserWithRoles);
    const userInviteDialog = useEditModelDialog(NewUserInvite);
    const confirm = useConfirmDialog();

    const handleEditOnClick = (user: OmniUser) => {
        userRolesDialog.show(async (updatedUser: Partial<UserWithRoles>) => {
            //Save the updated roles
            await Users.saveUserOrganizationRoles(updatedUser.id, currentOrganizationId, updatedUser.roles);
            await userHelpers.refreshData();
        }, "Edit User", { id: user.id, name: user.name, email: user.email, roles: user.roles?.map(r => r.name ?? "") });
    };

    const handleDeleteOnClick = (user: OmniUser) => {
        confirm.show("Delete User?", `Are you sure you want to delete this user (${user.email})?`, "Delete", async () => {
            await Organizations.removeUser(currentOrganizationId, user.id);
            userHelpers.refreshData();
        });
    }

    const handleInviteUserOnClick = () => {
        userInviteDialog.show(async (newUser: Partial<NewUserInvite>) => {
            await UserInvites.inviteUser(newUser as NewUserInvite);
            toastSuccess("Invite sent", "An invite has been sent to " + newUser.email);
            userInviteHelpers.refreshData();
        }, "Invite New User", new NewUserInvite({ organizationId: currentOrganizationId } as INewUserInvite));
    };

    const handleDeleteInviteOnClick = async (invite: NewUserInvite) => {
        try {
            await UserInvites.deleteInviteUser(invite);
            userInviteHelpers.refreshData();
            toastSuccess("Invite Deleted", "The invitation has been deleted");
        } catch (e) {
            toastError("Error Deleting Invite", e + "");
        }
    };

    const handleResendInvitation = async (invite: NewUserInvite) => {
        try {
            await UserInvites.resendInviteUser(invite);
            toastSuccess("Invite Sent", "A new invitation has been sent to " + invite.email);
        } catch (e) {
            toastError("Error Sending Invite", e + "");
        }
    };

    return (
        <DashboardLayout
            centerChildren={`${org?.name} - Manage Users`}
        >
            <>
                <SortableTable
                    isLoading={userHelpers.isLoading || userInviteHelpers.isLoading}
                    columns={[
                        { header: "Email", dataKey: "email", width: 150 },
                        { header: "Name", dataKey: "name", width: 150 },
                        {
                            header: "Status", width: 150, renderFunc: (u) => (u?.$type === OmniUser.$type ? "Active" + (u.id === user?.id ? " (You)" : "") : "Invite Sent")
                        },
                        {
                            header: "", width: 25, renderFunc: (u) => (
                                u?.$type === OmniUser.$type ? (
                                    u.id !== user?.id &&
                                    <DropDownMenu>
                                        <IconLink Icon={Icons.Edit} onClick={() => handleEditOnClick(u as OmniUser)} >Edit Roles</IconLink>
                                        <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(u as OmniUser)}>Delete</IconLink>
                                    </DropDownMenu>
                                ) : (
                                    <DropDownMenu>
                                        <IconLink Icon={Icons.Edit} onClick={() => handleResendInvitation(u as NewUserInvite)} >Re-Send Invitation</IconLink>
                                        <IconLink Icon={Icons.Trash} onClick={() => handleDeleteInviteOnClick(u as NewUserInvite)}>Delete Invitation</IconLink>
                                    </DropDownMenu>
                                )
                            )
                        },
                        {
                            header: <IconLink iconSize={20} Icon={Icons.PlusCircle} onClick={handleInviteUserOnClick}></IconLink>,
                            width: 25
                        }

                    ]}
                    data={
                        !userInvites && !users ? undefined :
                            [...userInvites ?? [], ...users ?? []]
                    } />
                {confirm.renderDialog()}

                {userRolesDialog.renderDialog((user, helpers) => (
                    <>
                        <LabeledText label="Email">{user.email}</LabeledText>
                        <LabeledText label="Name">{user.name}</LabeledText>
                        <InputRow>
                            <InputLabel>Roles</InputLabel>
                            <UserRoleInput onChange={(roles) => helpers.updateModel("roles", roles)} value={user.roles ?? []} />
                        </InputRow>
                    </>
                ))}

                {userInviteDialog.renderDialog((userInvite, helpers) => (
                    <>
                        <InputRow>
                            <Input label="Email" {...helpers.bindingsFor("email")} />
                        </InputRow>
                        <InputRow>
                            <Input label="First Name" {...helpers.bindingsFor("firstName")} />
                        </InputRow>
                        <InputRow>
                            <Input label="Last Name" {...helpers.bindingsFor("lastName")} />
                        </InputRow>
                        <InputRow>
                            <InputLabel>Roles</InputLabel>
                            <UserRoleInput onChange={(roles) => helpers.updateModel("roles", roles)} value={userInvite.roles ?? []} />
                        </InputRow>
                    </>
                ))}
            </>
        </DashboardLayout>
    );
}