import { LuxcomEndpointConfigDetector, ILuxcomEndpointConfigDetector } from "./Shared.Models.Api.V1.ClientEndpoints.LuxcomEndpointConfigDetector.gen";

export interface ILuxcomEndpointConfig {
	biuNum: number;

	detectors: ILuxcomEndpointConfigDetector[] | undefined;

	$type?: string | undefined;
}
export class LuxcomEndpointConfig {
	constructor(data?: ILuxcomEndpointConfig) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.detectors = data.detectors ? data.detectors.map((d: any) => new LuxcomEndpointConfigDetector(d)) : [];
	}

	biuNum: number = 0;

	detectors: LuxcomEndpointConfigDetector[] | undefined = [];

	static $type: string = "Shared.Models.Api.V1.ClientEndpoints.LuxcomEndpointConfig, ";
	$type?: string | undefined = LuxcomEndpointConfig.$type;
}

