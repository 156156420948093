import { LoadingIndicator } from "../shared";
import useRenderingTrace from "../../hooks/useRenderingTrace";
import { ChartSizeProps } from "../shared/charts";
import { PropsWithChildren, ReactNode } from "react";

export type ReportLoadingErrorWrapperProps =
    {
        isLoading?: boolean,
        hasNoData: boolean,
        error?: string
        size: ChartSizeProps
        children?: ReactNode
    } & PropsWithChildren;


export const ReportLoadingErrorWrapper: React.FC<ReportLoadingErrorWrapperProps> = ({ isLoading = false, hasNoData, error = '', size, children }) => {

    useRenderingTrace('ReportLoadingErrorWrapper', [isLoading, hasNoData, error, size, children]);

    return (<>{(isLoading || error?.length || hasNoData) && <div
        style={{
            top: (size.margin?.top ?? 20) / 2 - 10,
            width: '100%',
            height: `${size.height ?? 500 - ((size.margin?.bottom ?? 0) + (size.margin?.top ?? 0))}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
        }}
    >
        {isLoading && <LoadingIndicator isLoading={isLoading} message='Loading Data' isModal={true}></LoadingIndicator>}
        {!isLoading && !!!error.length && hasNoData && <h1>No data found </h1>}
        {!isLoading && !!error.length && <div className="flex flex-col"><h1>There was an error fetching the data</h1>  <div
            className="block bg-background-top-nav text-gray-500 font-mono p-2 rounded shadow h-[calc(1.25rem*5)] overflow-hidden whitespace-pre-wrap cursor-not-allowed"
        >{error}</div></div>}
    </div>}
        {(!isLoading && !error.length && !hasNoData) && children}
    </>);

}