import { Facility, getTargetStatusDisplayName, Target } from "@app/shared";
import { getTargetTypeDisplayName } from "@app/shared/src/models/Shared.TargetType.gen";
import React, { useMemo } from "react";
import { CheckboxInput, Icons, SortableTable } from "../../shared";
import {  formatInTimeZone } from "date-fns-tz";

type RecentTargetsTableProps = {
    facility: Facility;
    data: Target[];
    showTracksById: (id: string | undefined) => boolean;
    onShowTracksChange: (id: string | undefined, val: boolean) => void;
    isLoading?: boolean;
    onRowClick: (target: Target) => void;
};

export const RecentTargetsTable: React.FC<RecentTargetsTableProps> = ({ facility, data, showTracksById, onShowTracksChange, onRowClick, isLoading }) => {
    const getDeviceNameForTarget = (target?: Target) => facility.devices?.find(d => d.id === target?.deviceId)?.name ?? "";

    const zone = facility.localTimezoneId ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const timeZone = useMemo(() => {
        return formatInTimeZone(new Date(), zone, "z");
    }, [zone]);


    return (
        <SortableTable
            onRowClick={onRowClick}
            data={data}
            initialSortKey={"statusStarted"}
            initialSortDir="desc"
            isLoading={isLoading}
            columns={[
                {
                    header: "Show Tracks",
                    width: 75,
                    renderFunc: (t) => (
                        <div onClick={(e) => e.stopPropagation()} className="cursor-default">
                            <CheckboxInput
                                onChange={(e) => { onShowTracksChange(t?.id, e.target.checked) }}
                                checked={showTracksById(t?.id)}
                            />
                        </div>
                    )
                },
                { dataKey: "type", header: "Target Type", width: 150, renderFunc: (target) => getTargetTypeDisplayName(target!.type) },
                { header: "Detection Device", width: 175, renderFunc: getDeviceNameForTarget },
                { dataKey: "maximumWidth", header: "Size (m)", width: 100, renderFunc: (target) => `(${target?.maximumWidth?.toFixed(1)}, ${target?.maximumHeight?.toFixed(1)})` },
                { dataKey: "status", header: "Status", width: 100, renderFunc: (target) => target?.status ? getTargetStatusDisplayName(target?.status) : "" },
                { dataKey: "statusStarted", header: `Date (${timeZone})`, width: 135, renderFunc: (target) => target?.statusStarted ? formatInTimeZone(target?.statusStarted, zone, "MMM d, HH:mm") : "" },
                {
                    header: "",
                    renderFunc: (target) => (target?.netFacilityCountChange ?? 0) > 0 ?
                        <Icons.Entry color="green" className="w-6 h-6" />
                        :
                        (target?.netFacilityCountChange ?? 0) < 0 ?
                            <Icons.Exit color="red" className="w-6 h-6" />
                            :
                            "",
                    width: 25
                }
            ]}
        />
    );
}