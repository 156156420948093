import { DateOptions, IConfiguredTrafficReport } from "./IConfiguredTrafficReport";
import { AggregationPeriod } from "@app/shared";
import { GetFacilityUsageDataLoader } from "./FacilityUsageDataLoader";
import { formatInTimeZone } from "date-fns-tz";

function formatHour(timeZone:string, period: Date) {
    return formatInTimeZone(period,timeZone,'M/dd H:00');
}

export const FacilityUsageByHour = {
    name: "by Hour",
    description: "This report shows the total Inbound/Outbound Targets in the Location by hour for the specified range ",
    component: GetFacilityUsageDataLoader(AggregationPeriod.Hour, formatHour),
    defaultChartType: 'bar',
    defaultRange: DateOptions.LastWeek,
    key: 'fuh',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true,
        disabled: false,
        disallowChartTypeToggling: true
    }
} as IConfiguredTrafficReport;