import { DetectorStatus_V1, IDetectorStatus_V1 } from "./Shared.ClientEndpoints.Json.DetectorStatus_V1.gen";

export interface ILuxcomClientEndpointStateJson_V1 {
	lastUpdates: IDetectorStatus_V1[] | undefined;

	pendingUpdates: IDetectorStatus_V1[] | undefined;

	$type?: string | undefined;
}
export class LuxcomClientEndpointStateJson_V1 {
	constructor(data?: ILuxcomClientEndpointStateJson_V1) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.lastUpdates = data.lastUpdates ? data.lastUpdates.map((l: any) => new DetectorStatus_V1(l)) : [];
		this.pendingUpdates = data.pendingUpdates ? data.pendingUpdates.map((p: any) => new DetectorStatus_V1(p)) : [];
	}

	lastUpdates: DetectorStatus_V1[] | undefined = [];

	pendingUpdates: DetectorStatus_V1[] | undefined = [];

	static $type: string = "Shared.ClientEndpoints.Json.LuxcomClientEndpointStateJson_V1, ";
	$type?: string | undefined = LuxcomClientEndpointStateJson_V1.$type;
}

