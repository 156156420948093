/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { ClientEndpointHistory, IClientEndpointHistory, ClientEndpoint, IClientEndpoint, OptionValue, IOptionValue } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getHistory(id?: string): ApiResult<ClientEndpointHistory[]> {
	return get<IClientEndpointHistory[]>(`/api/clientendpoint/${ id }/history`).then(result => result === undefined ? undefined : result.map(o => new ClientEndpointHistory(o)));
}

export async function getAll(orgId?: string, facilityId?: string): ApiResult<ClientEndpoint[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facilityId !== undefined ? `facilityId=${facilityId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IClientEndpoint[]>(`/api/clientendpoint/${query}`).then(result => result === undefined ? undefined : result.map(o => new ClientEndpoint(o)));
}

export async function getOptions(orgId?: string, facilityId?: string): ApiResult<OptionValue[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facilityId !== undefined ? `facilityId=${facilityId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IOptionValue[]>(`/api/clientendpoint/options${query}`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function getWithStatusByFacilityId(orgId?: string, facilityId?: string): ApiResult<ClientEndpoint[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facilityId !== undefined ? `facilityId=${facilityId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IClientEndpoint[]>(`/api/clientendpoint/with-status-for-facility${query}`).then(result => result === undefined ? undefined : result.map(o => new ClientEndpoint(o)));
}

export async function update(endPoint: ClientEndpoint): ApiResult<string> {
	return post<string>(`/api/clientendpoint`, endPoint);
}

export async function deleteEndpoint(id?: string): ApiResult<undefined> {
	return del(`/api/clientendpoint/${ id }`);
}
