import { useContext, useEffect, useMemo, useState } from "react";
import { GoogleMap, MarkerF, InfoWindowF, } from "@react-google-maps/api";
import { Devices as DevicesApi, Device, Reports, useAuth, AggregationPeriod, AggregatedTargetAttribute } from '@app/shared';
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { Icons } from "../shared";
import { SimpleCard } from "../shared/SimpleCard";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { ReportContext, getGroupingDisplayName, groupBy } from "./reportHelpers";
import { BarStackMapped } from "./OptionallyStackedBarChart";
import { useFacilityCenter } from "../../helpers/mapHelpers";

interface MapReportProps {
    events?: boolean;
    devices?: Device[],
    center: google.maps.LatLng,
};

const MapReportLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig } = useContext(ReportContext);
    const [centerCoords, isLoading] = useFacilityCenter(reportConfig.context.facility ?? reportConfig.context.device?.facilityId);
    const { currentOrganizationId } = useAuth();
    const [devices, isDeviceLoading] = useFetch(() => DevicesApi.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);

    const allReady = props && !isLoading && !isDeviceLoading && (props.size.height ?? 0) > 10;
    return allReady ? (<MapReport {...{ ...props, center: centerCoords!, devices: devices }} />) : null;
}

const options = {
    disableDefaultUI: true,
    zoomControl: true
};

export const MapReport: React.FC<MapReportProps & TrafficReportProps> = (props) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const { width = 50, height = 600 } = props?.size;
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, AggregationPeriod.Day, searchParams), [searchParams]);

    const averageSpeeds = useMemo(() => {
        if (isLoading || !data?.length) return [];

        const stack = [] as BarStackMapped<{ mph: string, count: number, percentage: number, attributeValue: number }>[];
        if (reportConfig.grouping !== AggregatedTargetAttribute.None) {

            const rounded = data?.filter(d => d.count > 0).map(d => ({ count: d.count, percentage: 0, attributeValue: d.attributeValue, mph: Math.round(d.avgVelocity!) }));
            const gMph = groupBy(rounded!, 'attributeValue');

            for (const tvbp in gMph) {
                //const gTT = groupBy(gMph[speed], 'tt');
                if (!gMph[tvbp]) continue;
                const stackMap = {} as { [key: string]: number };
                const totalTargets = gMph[tvbp].reduce((agg, v) => v.count + agg, 0);
                const sum = gMph[tvbp].reduce((agg, v) => (v.mph * v.count) + agg, 0);
                stack.push({ mph: numberFormatter.format(Math.round(sum / totalTargets)), $$BarStackMap: stackMap, count: -1, percentage: -1, attributeValue: parseInt(tvbp) })
            }
            const avgs = stack.reduce((agg, v) => {
                var map = agg[0].$$BarStackMap;
                const key = v.attributeValue.toString();
                map[key] = parseInt(v.mph);
                return agg;
            }, [stack[0]]);
            return avgs;

        } else {
            const totalTargets = data.reduce((cv, agg) => cv + agg.count!, 0);
            const sum = data.reduce((cv, agg) => cv + agg.avgVelocity! * agg.count, 0);
            stack.push({ mph: numberFormatter.format(Math.round(sum / totalTargets)), $$BarStackMap: {} as { [key: string]: number }, count: totalTargets, percentage: -1, attributeValue: -1 })
        }
        return stack;
    }, [data, isLoading, numberFormatter, reportConfig.grouping]);

    const avWithData = Array.from(new Set(data?.filter(d => d.count > 0).map((d) => d.attributeValue)));

    const containerName = "map-container";

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    useEffect(() => {
        const ele = document.querySelector('.' + containerName) as HTMLDivElement;
        if (ele) {
            ele.style.width = width + 'px';
            ele.style.height = height + 'px';
        }
    }, [width, height, isMapLoaded])


    const [selectedMarker, setSelectedMarker] = useState<string | null>('marker1');
    const handleMarkerClick = (marker: any) => {
        setSelectedMarker(marker);
    };

    const handleCloseClick = (makerName: string) => {
        return () =>
            setSelectedMarker(null);
    };
    const deviceName = useMemo(() => {
        let name = reportConfig.context?.device?.name;
        if (!name?.length && props.devices?.length) {
            const devCount = reportConfig.deviceIds!.length;
            name = devCount > 1 ? 'All Devices' : props.devices.find(x => x.id === reportConfig.deviceIds![0])?.name;
        }
        return name ?? 'Unknown device';
    }, [props.devices, reportConfig.context.device?.name, reportConfig.deviceIds]);
    const Report = useMemo(() => {
        return (
            <div>
                {props.center && <GoogleMap
                    //ref={mapRef}
                    zoom={16}
                    center={props.center}
                    mapContainerClassName={containerName}
                    onLoad={() => setIsMapLoaded(true)}
                    onUnmount={() => setIsMapLoaded(false)}
                    options={options}
                >
                    <MarkerF
                        position={props.center}
                        clickable={true}
                        onClick={() => {
                            handleMarkerClick('marker1');
                        }}
                    >
                        {selectedMarker && (<InfoWindowF
                            onCloseClick={handleCloseClick('marker1')}
                            position={props.center}
                        >
                            <SimpleCard Icon={Icons.NavRadar} title={deviceName}>
                                <div>
                                    <h3 className="mb-3">Average Velocities</h3>
                                    <table>
                                        <tbody>
                                            {reportConfig.grouping !== AggregatedTargetAttribute.None && averageSpeeds && avWithData.map(av =>
                                                (<tr key={av}><td>{getGroupingDisplayName(reportConfig.grouping, av)}</td><td className="px-3">{numberFormatter.format(averageSpeeds[0]?.$$BarStackMap[av])} mph</td></tr>)
                                            )}
                                            {(reportConfig.grouping === AggregatedTargetAttribute.None) && averageSpeeds && (
                                                (<tr><td>All Targets</td><td className="px-3">{averageSpeeds[0]?.mph} mph</td></tr>)
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </SimpleCard>
                        </InfoWindowF>)}
                    </MarkerF>
                </GoogleMap>
                }
            </div>
        );
    }, [averageSpeeds, deviceName, selectedMarker, avWithData, reportConfig.grouping, props.center, numberFormatter]);
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >{Report}</ReportLoadingErrorWrapper>);
};


export const AverageVelocityMapReport = {
    name: "Average Speed Map Report",
    description: "This report shows the average speed of targets per device for the given time frame",
    component: MapReportLoader,
    key: 'velocity',
    defaultChartType: 'other',
    defaultRange: DateOptions.ThisYear,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;