import { AggregationPeriod, Reports, useAuth } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { useFetch } from "../../hooks";
import { useContext, useEffect, useMemo } from "react";
import { format, startOfWeek } from 'date-fns';
import { ReportContext, buildLabeler, buildStackedData, groupByKeys, hourFormatter, stackedTooltipBuilder } from "./reportHelpers";
import useRenderingTrace from "../../hooks/useRenderingTrace";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { toastError } from "../../helpers";
import { formatInTimeZone } from "date-fns-tz";
const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getWeekLabel(date: Date, sameYear: boolean): string {
    const startOfCurrentWeek = startOfWeek(date, { weekStartsOn: 0 });
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + 6); // end of week is 6 days after start of week
    const formattedStartDate = format(startOfCurrentWeek, sameYear ? 'M/d' : 'M/d/yy');
    const formattedEndDate = format(endOfCurrentWeek, sameYear ? 'M/d' : 'M/d/yy');
    const weekLabel = `${formattedStartDate} - ${formattedEndDate}`;
    return weekLabel;
}


type WeeklyComparisonDatum = {
    count: number,
    weekLabel: string,
    hourIndex: number
};

export const WeeklyComparisonDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, AggregationPeriod.Hour, searchParams), [searchParams]);
    useEffect(() => {
        if (error?.length) {
            toastError('Error loading report data', error ?? 'Unknown');
        }
    }, [error]);

    const extendedData = useMemo(() => {
        if (!data?.length || isLoading) {
            return [];
        }
        const sameYear = Array.from(new Set(data?.map((d) => formatInTimeZone(d.periodStart!, reportConfig.chartTimezone, 'yyyy')))).length === 1;
        const withDate = data.map(d => ({
            ...d,
        })).sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime());
        return withDate.map(w => ({
            count: w.count,
            weekLabel: getWeekLabel(w.periodStart!, sameYear),
            dayOfWeek: DaysOfWeek[w.periodStart!.getDay()],
            hourIndex: w.periodStart!.getDay() * 24 + w.periodStart!.getHours()
        }));

    }, [data, isLoading, reportConfig.chartTimezone]);

    const groupedByDay = useMemo(() => {
        if (!extendedData?.length || isLoading) {
            return [];
        }
        const reducedToDay = groupByKeys(extendedData, ['dayOfWeek', 'weekLabel'], 'count');
        return reducedToDay.sort((a, b) => DaysOfWeek.indexOf(a.dayOfWeek) - DaysOfWeek.indexOf(b.dayOfWeek));
    }, [extendedData, isLoading]);

    const dataWithDate = useMemo(() => {
        if (!extendedData?.length || isLoading) {
            return [];
        }
        const cData = groupByKeys(extendedData, ['hourIndex', 'weekLabel'], 'count');//[...data];
        return buildStackedData<WeeklyComparisonDatum>(cData, "count", "weekLabel")
            .sort((a, b) => a.hourIndex - b.hourIndex);
    }, [extendedData, isLoading]);

    const series = useMemo(() => {
        if (!dataWithDate?.length || isLoading) return undefined;

        const cData = Array.from(new Set(dataWithDate.map(d => d.weekLabel)));
        return cData?.map(week => {
            return {
                accessors: {
                    xAccessor: dc => dc?.hourIndex,
                    yAccessor: dc => dc?.$$BarStackMap[week] ?? 0
                },
                dataKey: week + ''
            }
        }) as SeriesDetails<BarStackMapped<WeeklyComparisonDatum>>[];
    }, [dataWithDate, isLoading]);
    const buildHourTicks = (numTicks: number) => buildLabeler(str => {
        const hourIndex = Math.floor(parseInt(str) % 24);

        return `${hourIndex === 0 ? '12' : hourIndex}:00 ${hourIndex < 12 ? 'am' : 'pm'}`;
    });

    const tickLabler24 = buildHourTicks(24);

    const toolTips = stackedTooltipBuilder<WeeklyComparisonDatum>(numberFormatter,
        (d, week) => week,
        (d, week) => numberFormatter.format(d.$$BarStackMap[week]),
        str => dateTimeLabel(str));
    // const groupedTips = stackedTooltipBuilder<WeeklyComparisonDatum>(
    //     (d, week) => { return 'a' },
    //     (d, week) => { return 'a' },
    //     (d) => {  return 'a' });

    useRenderingTrace('weekly volume chart', [searchParams, data, dataWithDate, series]);
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >{
        reportConfig.chartType === 'line' ?
            <OptionallySplitLineChart {...props} data={dataWithDate as BarStackMapped<WeeklyComparisonDatum>[]} series={series as any} xKey="hourIndex" yKey="count" toolTip={toolTips} xTickCount={28} lineSplitKey="weekLabel" lineSplitLabel={x => x.toString()} xTickLabeler={tickLabler24} />
            : <OptionallyStackedBarChart {...props} data={groupedByDay as BarStackMapped<WeeklyComparisonDatum & { dayOfWeek: string }>[]} xKey="dayOfWeek" yKey="count" xTickCount={7} barGroupKey="weekLabel" stackOrGroupLabel={x => x.toString()} />
    }</ReportLoadingErrorWrapper>);
}

export const WeeklyVolumeComparisonReport = {
    name: "Weekly Volume Comparison Report",
    description: "This report shows the hourly volume for every week in the selected data range",
    component: WeeklyComparisonDataLoader,
    key: 'wvc',
    defaultChartType: 'line',
    defaultRange: DateOptions.ThisMonth,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: false
    }
} as IConfiguredTrafficReport;

function dateTimeLabel(x: BarStackMapped<WeeklyComparisonDatum>): string {
    const dayIdx = Math.floor(x.hourIndex / 24);
    const hour = x.hourIndex - (dayIdx * 24);
    var weekDay = DaysOfWeek[dayIdx];
    const hourRange = hourFormatter(hour + '');
    return weekDay + ' ' + hourRange;
}