export enum AggregationPeriod {
	FiveMinutes = 5,
	Day = 24,
	Month = 30,
	Hour = 60,
	Year = 365,
}

export const AggregationPeriodDisplayNames: { [key in keyof typeof AggregationPeriod]?: string} = {
	"FiveMinutes": "Five Minutes",
	5 : "Five Minutes",
	"Day": "Day",
	24 : "Day",
	"Month": "Month",
	30 : "Month",
	"Hour": "Hour",
	60 : "Hour",
	"Year": "Year",
	365 : "Year",
}

export const getAggregationPeriodDisplayName = (key: AggregationPeriod | (keyof typeof AggregationPeriod & string)) => {
	return AggregationPeriodDisplayNames[key];
}

