import { Guid, IGuid } from "./System.Guid.gen";
import { Single, ISingle } from "./System.Single.gen";
import { TargetTypes } from "./Shared.TargetTypes.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IEventTriggerBase {
	id: string | undefined;

	organizationId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	clientEndpointId: string | undefined;

	targetFilter: TargetTypes | number ;

	processOnDevice: boolean;

	minValue: number | undefined;

	maxValue: number | undefined;

	endpointConfig: string | undefined;

	name: string | undefined;

	$type?: string | undefined;
}
export class EventTriggerBase {
	constructor(data?: IEventTriggerBase) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	organizationId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	@IsNotEmpty()
	clientEndpointId: string | undefined;

	targetFilter: TargetTypes | number  = 0;

	processOnDevice: boolean = false;

	minValue: number | undefined;

	maxValue: number | undefined;

	endpointConfig: string | undefined;

	name: string | undefined;

	static $type: string = "CoreApi.Models.EventTriggers.EventTriggerBase, ";
	$type?: string | undefined = EventTriggerBase.$type;
}

