import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregationPeriod } from "@app/shared";
import { TotalVolumeDataLoader } from "./TotalVolumeDataLoader";

const ThirteenBinDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    //const { reportConfig, searchParams } = useContext(ReportContext);
    //const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, AggregationPeriod.Day, searchParams), [searchParams]);

    // const targetToFHWAClassMap: Record<TargetType, FHWAClass> = {
    //     [TargetType.Bike]: FHWAClass.Class1,
    //     [TargetType.Motorcycle]: FHWAClass.Class1,
    //     [TargetType.Boat]: FHWAClass.Class3,
    //     [TargetType.Bus]: FHWAClass.Class4,
    //     [TargetType.Camper]: FHWAClass.Class5,
    //     [TargetType.Car]: FHWAClass.Class2,
    //     [TargetType.PassengerTruck]: FHWAClass.Class3,
    //     [TargetType.Pedestrian]: FHWAClass.Class1,
    //     [TargetType.Bobtail]: FHWAClass.Class6,
    //     [TargetType.SemiTrailerTruck]: FHWAClass.Class9,
    //     [TargetType.Trailer]: FHWAClass.Class3,
    //     [TargetType.UtilityVehicle]: FHWAClass.Class2,
    //     [TargetType.Wheel]: FHWAClass.Class15Unknown,
    //     [TargetType.Unknown]: FHWAClass.Class15Unknown,
    //     [TargetType.Unspecified]: FHWAClass.Class15Unknown,
    // };

    // const getClass = (tt: TargetType | string): FHWAClass => {
    //     if (typeof tt === "string") {
    //         const enumValue = TargetType[tt as keyof typeof TargetType];
    //         if (enumValue !== undefined) {
    //             return targetToFHWAClassMap[enumValue] || FHWAClass.Class15Unknown;
    //         }
    //     }
    //     return targetToFHWAClassMap[tt as TargetType] || FHWAClass.Class10;
    // };

    // const groupKey = reportConfig.grouping === AggregatedTargetAttribute.TargetType ? undefined : 'fhwaClass';
    // const tbData = useMemo(() => {
    //     if (reportConfig.grouping === AggregatedTargetAttribute.TargetType) {
    //         return data;
    //     }
    //     const classedData= data?.map(datum => {
    //         (datum as any)['fhwaClass'] = getClass(datum.targetType);
    //         return datum;
    //     });
    //     return classedData?.length?groupByKeys(classedData,['periodStart','fhwaClass' as any],'count'):data;
    // }, [data]);
    // return (<TotalVolumeChart
    //     {...props}
    //     aggregationPeriod={AggregationPeriod.Day}
    //     startDate={searchParams.inclusiveStart}
    //     endDate={searchParams.exclusiveEnd}
    //     data={tbData}
    //     groupKey={groupKey}
    //     groupByTargetType={reportConfig.grouping != AggregatedTargetAttribute.None}
    //     chartTimezone={reportConfig.chartTimezone}
    //     chartType={reportConfig.chartType as 'bar' | 'line'}
    // />);
    return (<TotalVolumeDataLoader
        {...props}
        aggregationPeriod={AggregationPeriod.Day}
    />);
}


export const TotalVolumeByDay: IConfiguredTrafficReport = {
    name: "by Day",
    key: "tvd",
    description: "This report shows total traffic by day for the given range",
    component: ThirteenBinDataLoader,
    defaultChartType: 'line',
    defaultRange: DateOptions.ThisMonth,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};