import { Guid, IGuid } from "./System.Guid.gen";
import { Int32, IInt32 } from "./System.Int32.gen";
import { AggregatedTargetAttribute } from "./Reports.AggregatedTargetAttribute.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface ITrafficReportConfiguration {
	inclusiveStart: string;

	exclusiveEnd: string;

	grouping: AggregatedTargetAttribute;

	facilityIds: string[] | undefined;

	deviceIds: string[] | undefined;

	groupingValues: number[];

	$type?: string | undefined;
}
export class TrafficReportConfiguration {
	constructor(data?: ITrafficReportConfiguration) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.inclusiveStart = new Date(data.inclusiveStart);
		this.exclusiveEnd = new Date(data.exclusiveEnd);
	}

	@IsNotEmpty()
	inclusiveStart: Date = new Date();

	@IsNotEmpty()
	exclusiveEnd: Date = new Date();

	grouping: AggregatedTargetAttribute = 0;

	facilityIds: string[] | undefined;

	deviceIds: string[] | undefined;

	groupingValues: number[] = [];

	static $type: string = "CoreApi.Models.Reports.TrafficReportConfiguration, ";
	$type?: string | undefined = TrafficReportConfiguration.$type;
}

