import { EmailClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { Input, InputRow } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditEmailClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditEmailClientEndpoint: React.FC<EditEmailClientEndpointProps> = (props) => {
    const [, helper] = useModel(EmailClientEndpoint, props.endpoint as EmailClientEndpoint);
    const onChange = (newEndpoint: Partial<EmailClientEndpoint>) => {
        props.onChange(newEndpoint as EmailClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><Input label="Email Address" {...helper.bindingsFor("emailAddress", onChange)} /></InputRow>
        </>
    );
}