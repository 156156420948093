import { PointF, IPointF } from "./Shared.Geometry.PointF.gen";
import { SizeF, ISizeF } from "./Shared.Geometry.SizeF.gen";

export interface IRectangleF {
	location: IPointF | undefined;

	size: ISizeF | undefined;

	x: number;

	y: number;

	width: number;

	height: number;

	left: number;

	top: number;

	right: number;

	bottom: number;

	isEmpty: boolean;

	$type?: string | undefined;
}
export class RectangleF {
	constructor(data?: IRectangleF) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.location) { this.location = new PointF(data.location); }
		if (data.size) { this.size = new SizeF(data.size); }
	}

	location: PointF | undefined;

	size: SizeF | undefined;

	x: number = 0;

	y: number = 0;

	width: number = 0;

	height: number = 0;

	left: number = 0;

	top: number = 0;

	right: number = 0;

	bottom: number = 0;

	isEmpty: boolean = false;

	static $type: string = "Shared.Geometry.RectangleF, ";
	$type?: string | undefined = RectangleF.$type;
}

