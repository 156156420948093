import { FunctionComponent, useMemo } from "react";
import { MultiSelect, MultiSelectProps, SimpleOption } from "../shared/inputs/MultiSelect";

export type TargetTypeMultiSelectProps = {
    values: number[],
    onChange: (values: number[]) => void,
    options: SimpleOption[]
} & Omit<Omit<Omit<MultiSelectProps, 'value'>, 'onChange'>, 'options'>;

const allOpt = { label: 'All', value: -1 };

export const TargetTypeMultiSelect: FunctionComponent<TargetTypeMultiSelectProps> = (props) => {
    const { values, options, onChange } = props;

    const allOptions = useMemo(() => [allOpt, ...options], [options]);
    const selectedValues = values.length === options.length ? [-1] : [...values];
    const handleSelection = (e: number[]) => {
        if (e.find(x => x === allOpt.value && !!!selectedValues.find(x => x === allOpt.value))) {
            onChange(options.map(x => x.value as number));
        } else {
            onChange(e.filter(x => x !== allOpt.value));
        }
    }
    return (
        <MultiSelect {...props} label={props.label} value={selectedValues} options={allOptions} onChange={e => handleSelection(e as number[])} />
    );
};