import { Device } from "@app/shared";
import { useEffect, useRef } from "react";

type WebRtcPlayerProps = {
    device: Device
};

export const WebRtcPlayer: React.FC<WebRtcPlayerProps> = ({ device }) => {
    const videoPlayer = useRef<HTMLVideoElement>(null);

    async function PeerConnection(media: string) {
        const pc = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        })

        var localTracks: MediaStreamTrack[] = [];

        if (/video|audio/.test(media)) {
            const tracks = ['video', 'audio']
                .filter(kind => media.indexOf(kind) >= 0)
                .map(kind => pc.addTransceiver(kind, { direction: 'recvonly' }).receiver.track)
            localTracks.push(...tracks)
        }

        if (videoPlayer.current) {
            videoPlayer.current.srcObject = new MediaStream(localTracks)
        }

        return pc
    }

    function getOffer(pc: RTCPeerConnection, timeout?: number) {
        return new Promise<string>((resolve, reject) => {
            pc.addEventListener('icegatheringstatechange', () => {
                if (pc.iceGatheringState === 'complete') resolve(pc.localDescription?.sdp ?? "")
            })

            pc.createOffer().then(offer => pc.setLocalDescription(offer))

            setTimeout(() => resolve(pc.localDescription?.sdp ?? ""), timeout || 5000)
        })
    }
    function decode(buffer: ArrayBuffer | number[]) {
        return String.fromCharCode(...new Uint8Array(buffer))
    }

    function encode(string: string) {
        return Uint8Array.from(string, c => c.charCodeAt(0))
    }

    async function cipher(share: string, pwd: string) {
        const hash = await crypto.subtle.digest('SHA-256', encode(share))
        const nonce = (Date.now() * 1000000).toString(36)

        const ivData = await crypto.subtle.digest('SHA-256', encode(share + ':' + nonce))
        const keyData = await crypto.subtle.digest('SHA-256', encode(nonce + ':' + pwd))
        const key = await crypto.subtle.importKey(
            'raw', keyData, { name: 'AES-GCM' }, false, ['encrypt', 'decrypt'],
        )

        return {
            hash: btoa(decode(hash)),
            nonce: nonce,
            encrypt: async function (plaintext: string) {
                const cryptotext = await crypto.subtle.encrypt(
                    { name: 'AES-GCM', iv: ivData.slice(0, 12), additionalData: encode(nonce) },
                    key, encode(plaintext),
                )
                return btoa(decode(cryptotext))
            },
            decrypt: async function (cryptotext: string) {
                const plaintext = await crypto.subtle.decrypt(
                    { name: 'AES-GCM', iv: ivData.slice(0, 12), additionalData: encode(nonce) },
                    key, encode(atob(cryptotext)),
                )
                return decode(plaintext)
            }
        }
    }
    async function connect(share: string, pwd: string, media?: string, tracker?: string) {
        const crypto = await cipher(share, pwd)
        const pc = await PeerConnection(media || 'video+audio')
        const offer = await crypto.encrypt(await getOffer(pc))

        const ws = new WebSocket(tracker || 'wss://tracker.openwebtorrent.com/')
        ws.addEventListener('open', () => {
            ws.send(JSON.stringify({
                action: 'announce',
                info_hash: crypto.hash,
                peer_id: Math.random().toString(36).substring(2),
                offers: [{
                    offer_id: crypto.nonce,
                    offer: { type: 'offer', sdp: offer },
                }],
                numwant: 1,
            }))
        })

        ws.addEventListener('message', async (ev) => {
            const msg = JSON.parse(ev.data)
            if (!msg.answer) return

            const answer = await crypto.decrypt(msg.answer.sdp)
            await pc.setRemoteDescription({ type: 'answer', sdp: answer })

            ws.close()
        })

    }
    useEffect(() => {
        const pwd = device?.deviceKey;
        const share = device?.id;
        connect(share ?? "", pwd ?? "");
    }, [device]);

    return (
        <video ref={videoPlayer} id="video" autoPlay={true} controls playsInline={true} muted className="w-full mx-auto my-auto" />
    );
}