import { Int32, IInt32 } from "./System.Int32.gen";
import { AggregatedTargetAttribute } from "./Reports.AggregatedTargetAttribute.gen";

export interface IAverageDailyVolume {
	year: number;

	month: number | undefined;

	aggregatedAttribute: AggregatedTargetAttribute;

	attributeValue: number | undefined;

	avgVolume: number;

	numDaysIncluded: number;

	$type?: string | undefined;
}
export class AverageDailyVolume {
	constructor(data?: IAverageDailyVolume) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	year: number = 0;

	month: number | undefined;

	aggregatedAttribute: AggregatedTargetAttribute = 0;

	attributeValue: number | undefined;

	avgVolume: number = 0;

	numDaysIncluded: number = 0;

	static $type: string = "CoreApi.Models.Reports.AverageDailyVolume, ";
	$type?: string | undefined = AverageDailyVolume.$type;
}

